import React, { useEffect, useState } from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import QueryApiService from '../utils/reactQueryApi';
import RegisterForm from '../component/courses/registerForm';
import List from '../component/courses/list';

const Courses = () => {
  const [data, setData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const reactQueryDataApi = new QueryApiService('courses/public');
  useEffect(async () => {
    const result = (await reactQueryDataApi.getData('?status=active&showOnWebsite=true'))?.data;
    setData(result);
  }, []);

  return (
    <>
      <SEO title="الدورات" />
      <main className="main-wrapper">
        <HeaderOne />
        <div className="breadcrum-area ">
          <div className="container">
            <div className={`section-heading mb-2 `}>
              <img src={process.env.PUBLIC_URL + '/images/others/bill-pay.png'} width="25%" />
            </div>

            <div className="row justify-content-center">
              <span style={{ fontSize: '4vh' }} className="section-heading">
                اذا كنت ترغب في التسجيل في قائمة الانتظار يرجى الضغط على زر التسجيل
              </span>
            </div>
            <div className="row justify-content-center">
              <span className="section-heading" style={{ marginTop: '-4%', color: 'red' }}>
                سيتم التواصل معاك عند افتتاح الدورة من قبل المدرب.
              </span>
            </div>

            <div className="row justify-content-center" style={{ marginTop: '-2%' }}>
              <div className="col-md-3 row justify-content-center pb-4">
                <button
                  type="button"
                  className="btn btn-primary  "
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  التسجيل
                </button>
              </div>
            </div>

            <hr />
            <RegisterForm show={showForm} handleClose={() => setShowForm(false)} />
            <List data={data} />
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default Courses;
