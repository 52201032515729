import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const AboutFour = () => {
  return (
    <div className="section section-padding case-study-featured-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-4">
            <div className="case-study-featured-thumb text-start">
              <img
                src={process.env.PUBLIC_URL + "/images/about/aboutus-1.jpg"}
                alt="travel"
              />
            </div>
          </div>
          <div className="col-xl-6 col-lg-8">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <h3 className="title">من نحن</h3>
                <p>
                  تأسست شركة نوميديا لتقنية المعلومات لتكون كيان يدفع باتجاه
                  تطور صناعة البرمجيات في المنطقة وفق أحدث الطرق العلمية وترسيخ
                  فكر الإبداع و الإتقان في هذه الصناعة اعتماداً على كوادر ذات
                  مستوى علمي مميز وخبرة كبيرة بالسوق البرمجية، حيث تهدف لتطوير
                  أفكار ومشاريع برمجية رائدة وإدارتها، بالإضافة لتقديم حلول
                  متكاملة لعملائها في مجالات تطبيقات الويب وتطبيقات الهواتف
                  الذكية بكافة أنظمتها وإنجاز متطلباتهم بدقة وكفاءة عالية.
                </p>
                <p>
                  تتميز منظومات نوميديا في سهولة الاستخدام والسرعة في الاداء.{" "}
                </p>
              </div>
              <div className="case-study-counterup">
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? <CountUp end="30" duration={1} /> : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">خبرة في البرمجيات</span>
                </div>
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? (
                            <CountUp end="1000" duration={1} />
                          ) : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">عميل</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
