import React from 'react';
import { useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from 'react-google-recaptcha';
import QueryApiService from '../../utils/reactQueryApi';
import { set } from 'lodash';
import Alert from 'react-bootstrap/Alert';

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      تم الارسال بنجاح
    </Alert>
  );
};
const ErrorResult = ({ message }) => {
  return (
    <div className="alert alert-danger" role="alert">
      {message}
    </div>
  );
};

function Example(props) {
  const { show, handleClose } = { ...props };
  const captchaRef = useRef(null);
  const [data, setData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const reactQueryDataApi = new QueryApiService('/waitingList');

  const onValueChange = (key, value) => {
    setData({ ...set(data, key, value) });
  };
  const submitData = async (e) => {
    e.preventDefault();
    const { name, email, phoneNumber, entity } = { ...data };
    const token = captchaRef.current.getValue();
    if (!name || !email || !phoneNumber || !entity) {
      setErrorMessage('الرجاء ملء جميع الحقول');
    } else if (!token) {
      setErrorMessage('اضغط على recapacha');
    } else {
      setLoading(true);
      const result = await reactQueryDataApi.postData({ ...data });
      if (result?.success) {
        setLoading(false);
        setSuccessMessage('تم تقديم طلبك بنجاح');
        setTimeout(() => {
          setSuccessMessage(null);
          setData({});
          setErrorMessage(null);
          handleClose();
        }, 2000);
      } else if (!result?.response?.data?.success) {
        setErrorMessage(result?.response?.data?.msg);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ fontSize: '3.0vh' }}>التسجيل في الدورة</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && <ErrorResult message={errorMessage} />}
          <div className="form-group">{successMessage ? <Result /> : null}</div>

          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>الاسم (ثلاثي)</Form.Label>
              <Form.Control
                type="text"
                placeholder="مثال : محمد عبدالكريم فريعة"
                onChange={(e) => onValueChange('name', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>رقم الهاتف</Form.Label>
              <Form.Control
                type="text"
                placeholder="مثال : 0917575309"
                onChange={(e) => onValueChange('phoneNumber', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>البريد الالكتروني</Form.Label>
              <Form.Control
                type="email"
                placeholder=" mohammedfrea@gmail.com : مثال  "
                onChange={(e) => onValueChange('email', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>جهة العمل</Form.Label>
              <Form.Control
                type="text"
                placeholder="مثال : وزارة العمل"
                onChange={(e) => onValueChange('entity', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Select
                onChange={(e) => onValueChange('typeOfCourse', e.target.value)}
                aria-label="Default select example"
              >
                <option>نوع المنظومة</option>
                <option value="منظومة المرتبات">منظومة المرتبات</option>
                <option value="منظومة الحسابات">منظومة الحسابات</option>
                <option value="منظومة شؤون العاملين">منظومة شؤون العاملين</option>
                <option value="منظومة المخازن">منظومة المخازن</option>
                <option value="منظومة الارشفة">منظومة الارشفة</option>
                <option value="منظومة الخزينة">منظومة الخزينة</option>
                <option value="منظومة الايرادات">منظومة الايرادات</option>
                <option value="منظومة البصمة">منظومة البصمة</option>
                <option value="منظومة الاصول">منظومة الاصول</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3 mt-4" controlId="exampleForm.ControlInput1">
              <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            الغاء
          </Button>

          <Button variant="primary" onClick={submitData}>
            {loading ? (
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              'ارسال'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Example;
