import axiosClient from './axiosClient';
export default class QueryApiService {
  constructor(subUrl) {
    this.url = subUrl;
  }

  async getData(extraUrl = '') {
    return axiosClient
      .get(this.url + extraUrl)
      .then((res) => res.data)
      .catch((error) => {
        // this when token expired
        if (error?.response?.data?.msg == 'الرجاء تسجيل الدخول') {
          sessionStorage.removeItem('user');
          window.location.reload();
        }
        return error;
      });
  }
  async postData(fields) {
    return axiosClient
      .post(this.url, fields)
      .then((res) => res.data)
      .catch((error) => {
        return error;
      });
  }

  async updateData(id, fields) {
    return axiosClient.put(this.url + '/' + id, fields);
  }

  async deletetData(id) {
    return axiosClient.delete(this.url + '/' + id);
  }
}
