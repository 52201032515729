import React from 'react';
import dayjs from 'dayjs';
import { HOST_URL } from '../../constants/api';
import Countdown from 'react-countdown';

export default function List(props) {
  const { data } = { ...props };
  return data?.map((item, index) => (
    <div key={{ index }} style={{ backgroundColor: '' }} className="border shadow rounded p-4">
      <div className={`section-heading mb-2 `}>
        <span style={{ fontFamily: 'changa', fontWeight: 'bold', fontSize: '3.5vh' }}>
          {item.title}
        </span>
      </div>
      <hr />
      <div className="row p-4">
        <div className="col-md-1">
          <img
            src={
              item?.createdBy?.photo
                ? // src={logoUrl ? `${HOST_URL + logoUrl}` : NoLogo}

                  HOST_URL + item?.createdBy?.photo
                : 'https://mdbootstrap.com/img/new/avatars/2.jpg'
            }
            className="rounded-circle"
            alt=""
          />
        </div>
        <div className="col-md-3">
          <div className="row ">
            <span style={{ fontWeight: 'bold', fontSize: ' 3.0vh' }}>{item?.createdBy?.name}</span>
          </div>
          <div className="row ">
            <span>مهندس</span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row justify-content-center">
            <span className="section-heading" style={{ fontWeight: 'bold', color: 'red' }}>
              <Countdown date={new Date(item?.program[0]?.date)} />
            </span>
          </div>
        </div>

        <div className="col-md-3 offset-md-1">
          <div className="row justify-content-center col-md-7">
            {/* {item?.canRegisterFromWebsite && item?.audience?.length < item?.maxAudienceNumber && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setCourseId(item?._id);
                  setShowForm(true);
                }}
              >
                التسجيل
              </button>
            )} */}
          </div>
          <div className="row justify-content-center">
            <span className="mt-4" style={{ fontWeight: 'bold' }}>
              عدد المتدربين : {item?.audience?.length}
            </span>
          </div>
          <div className="row justify-content-center">
            {item?.price > 0 && <span className="mt-3">رسوم الدورة - {item?.price} د.ل</span>}
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">اليوم</th>
              <th scope="col">عنوان البرنامج</th>
              <th scope="col">التاريخ</th>
              <th scope="col">التوقيت</th>
              <th scope="col">الوصف</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, i) => {
              return item?.program?.map((row, index) => (
                <tr key={`ky-${index}`}>
                  <th scope="row">{index + 1}</th>
                  <td>{row?.title}</td>
                  <td>{row?.date?.slice(0, 10)}</td>
                  <td>{`${dayjs(row?.timeFrom)?.format('HH:mm')} - ${dayjs(row?.timeTo)?.format(
                    'HH:mm'
                  )}`}</td>
                  <td>{row?.whatYouWillLearn[0]}</td>
                </tr>
              ));
            })}
          </tbody>
        </table>
      </div>
    </div>
  ));
}
