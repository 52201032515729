import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import { set } from "lodash";

import QueryApiService from "../../utils/reactQueryApi";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      تم الارسال بنجاح
    </Alert>
  );
};
const ErrorResult = ({ message }) => {
  return (
    <div class="alert alert-danger" role="alert">
      {message}
    </div>
  );
};
const FormTwo = () => {
  const captchaRef = useRef(null);
  const [data, setData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const reactQueryDataApi = new QueryApiService("/contactUs");

  const onValueChange = (key, value) => {
    setData({ ...set(data, key, value) });
  };
  const submitData = async (e) => {
    e.preventDefault();
    const { name, email, phoneNumber, title, body } = { ...data };
    const token = captchaRef.current.getValue();
    if (!name || !email || !phoneNumber || !title || !body) {
      setErrorMessage("الرجاء ملء جميع الحقول");
    } else if (!token) {
      setErrorMessage("اضغط على recapacha");
    } else {
      setLoading(true);
      const result = await reactQueryDataApi.postData(data);
      if (result?.success) {
        setLoading(false);
        setSuccessMessage("تم تقديم طلبك بنجاح");
        setTimeout(() => {
          setSuccessMessage(null);
          setData({});
          setErrorMessage(null);
        }, 2000);
      }
    }
  };

  return (
    <div className="axil-contact-form">
      <div className="form-group">
        <label>الاسم</label>
        <input
          className="form-control"
          name="contact-name"
          value={data?.name || ""}
          onChange={(e) => onValueChange("name", e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>البريد الالكتروني</label>
        <input
          className="form-control"
          name="contact-email"
          value={data?.email || ""}
          onChange={(e) => onValueChange("email", e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>رقم الهاتف</label>
        <input
          className="form-control"
          name="contact-phone"
          value={data?.phoneNumber || ""}
          onChange={(e) => onValueChange("phoneNumber", e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>العنوان</label>
        <input
          className="form-control"
          name="contact-phone"
          value={data?.title || ""}
          onChange={(e) => onValueChange("title", e.target.value)}
        />
      </div>

      <div className="form-group mb--40">
        <label>كيف يمكننا مساعدتك ؟</label>
        <textarea
          className="form-control"
          name="contact-message"
          rows="4"
          value={data?.body || ""}
          onChange={(e) => onValueChange("body", e.target.value)}
        ></textarea>
      </div>
      <div className="form-group">
        <div className="row justify-content-center p-2">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            ref={captchaRef}
          />
        </div>
        <br />
        <button
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
          onClick={submitData}
        >
          {loading ? (
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            "ارسال"
          )}
        </button>
      </div>
      {errorMessage && <ErrorResult message={errorMessage} />}
      <div className="form-group">{successMessage ? <Result /> : null}</div>
    </div>
  );
};

export default FormTwo;
