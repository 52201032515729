import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from 'react-icons/fa';

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      {/* <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-grid"}>Blog</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-details/1"}>
                Standard Post
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-details/2"}>
                Gallery Post
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-details/3"}>
                Video Post
              </Link>
            </li>
          </ul> */}

      <ul className="mainmenu">
        <li>
          <Link to={process.env.PUBLIC_URL + '/'}>الرئيسية</Link>
        </li>

        <li>
          <Link to={process.env.PUBLIC_URL + '/about-us'}>عن الشركة</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + '/download'}>التنزيلات</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + '/project'}>اعمالنا</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + '/courses'}>الدورات</Link>
        </li>

        {/* <li>
          <Link to={process.env.PUBLIC_URL + "/pricing-table"}>الاسعار</Link>
        </li> */}
        <li>
          <Link to={process.env.PUBLIC_URL + '/team'}>الفريق</Link>
        </li>

        <li>
          <Link to={process.env.PUBLIC_URL + '/contact'}>تواصل معنا</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
