import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import ProcessOne from "../component/process/ProcessOne";
import AboutThree from "../component/about/AboutThree";
import AboutFour from "../component/about/AboutFour";
import AboutFive from "../component/about/AboutFive";

const AboutUs = () => {
  return (
    <>
      <SEO title="من نحن" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <AboutFour />
        <AboutThree />
        <AboutFive />
        {/* <ProcessOne /> */}
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default AboutUs;
