import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";

const Datas = [
  {
    id: 1,
    title: "الخبرة المطلوبة",
    para: "يمتلك فريق نوميديا الخبرة الطويلة في الانظمة التي توفر الحلول لمختلف القطاعات خصوصا المالية منها .",
  },
  {
    id: 2,
    title: "الانظمة المتنوعة",
    para: "اكثر من 20 نوع من الانظمة المختلفة .",
  },
  {
    id: 3,
    title: "الاسعار المنافسة",
    para: "الاسعار المنافسة لمنتجات شركة نوميديا كان احد اهم الاسباب في انتشارها",
  },
  {
    id: 4,
    title: "خدمات مابعد البيع",
    para: "الحرص على متابعة العميل بعد البيع احد اهم استراتيجيات الشركة .",
  },
  {
    id: 5,
    title: "الدفع بعد التجربة",
    para: "يمكن للعميل من تجربة المنتجات لفترة 6 اشهر قبل الشراء.",
  },
];

const AboutThree = () => {
  return (
    <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
      <div className="container">
        <SectionTitle
          subtitle="لماذا نحن"
          title="مايميز نوميديا؟"
          description="نوميديا توفر الحلول المتنوعة ما يجعلها اختيارك الافضل"
          textAlignment="heading-left heading-light-left mb--100"
          textColor=""
        />

        <div className="row">
          {Datas.map((data) => (
            <div className="col-lg-4" key={data.id}>
              <div className="about-quality">
                <h3 className="sl-number">{data.id}</h3>
                <h5 className="title">{data.title}</h5>
                <p>{data.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="list-unstyled shape-group-10">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/circle-1.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-3.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"}
            alt="Circle"
          />
        </li>
      </ul>
    </div>
  );
};

export default AboutThree;
