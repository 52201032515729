import React from "react";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import FormTwo from "../component/contact/FormTwo";
import SectionTitle from "../elements/section-title/SectionTitle";
import ContactLocation from "../component/contact/ContactLocation";

const Contact = () => {
  return (
    <>
      <SEO title="Blog Grid" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="تواصل معنا" page="تواصل معنا" />

        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <div className="contact-form-box shadow-box mb--30">
                  <h3 className="title">الرجاء تعبئة جميع الحقول</h3>
                  <FormTwo />
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                  <h4 className="title">رقم الهاتف</h4>
                  <p>ساعات العمل من 8.30 صباحا الى 4.00 مساء </p>
                  <h4 className="phone-number">
                    <a href="tel:1234567890">21-4444330 (218)+</a>
                  </h4>
                  <h4 className="phone-number">
                    <a href="tel:1234567890">91-7575309 (218)+</a>
                  </h4>
                </div>
                <div className="contact-info mb--30">
                  <h4 className="title">البريد الالكتروني</h4>
                  <p>الرد سيكون في خلال 48 ساعة ان شاء الله</p>
                  <h4 className="phone-number">
                    <a href="mailto:info@example.com">support@numedia.ly</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>

        <div className="section section-padding bg-color-dark overflow-hidden">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <SectionTitle
                  subtitle="اين تجدنا"
                  title="موقعنا"
                  description=""
                  textAlignment="heading-light-left"
                  textColor=""
                />
                <div className="row">
                  <ContactLocation />
                </div>
              </div>
              <div className="col-md-6 " style={{ marginTop: "18%" }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d209.39996977991873!2d13.185301063145145!3d32.88760377832728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13a892cf55214a07%3A0x67e0e0a40d742e23!2z2YXYsdmD2LIg2LfYsdin2KjZhNizINmE2KrZgtmG2YrYqSDYp9mE2YXYudmE2YjZhdin2Ko!5e0!3m2!1sen!2sly!4v1718020640292!5m2!1sen!2sly"
                  width="600"
                  height="450"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <div className="row mt-4">
                  <h4 style={{ color: "white" }}>موقعنا على الخريطة</h4>
                </div>
              </div>
            </div>
          </div>

          <ul className="shape-group-11 list-unstyled">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-6.png"}
                alt="line"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="line"
              />
            </li>
          </ul>
        </div>

        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default Contact;
