import React from "react";
import { Link } from "react-router-dom";
import CounterUp from "./CounterUp";

const CounterUpTwo = () => {
  return (
    <div className="section section-padding expert-counterup-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="section-heading heading-left">
              <span className="subtitle">لدينا ماتحتاجه واكثر</span>
              <h2>مايميزنا على غيرنا ؟</h2>
              <p className="mb--50">
                مايميز منتاجاتنا انها مجربة بعناية ,كما انها واسعة الانتشار
                مايجعلها ذائما محدثة باخر متطلبات الزبائن
              </p>
            </div>
          </div>
          <div className="col-lg-6 offset-xl-1">
            <div className="row">
              <CounterUp
                colSize="col-sm-6"
                layoutStyle="counterup-style-2"
                evenTopMargin=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterUpTwo;
