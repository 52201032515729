import React from 'react';
import { Link } from 'react-router-dom';

const AboutOne = () => {
  return (
    <section className="section  bg-color-light">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-us">
              <div className="section-heading heading-left mb-0">
                <span className="subtitle"> مع مجموعة من نخبة المدربين</span>
                <h2 className="title mb--40">التدريب على منظوماتنا</h2>
                <p>
                  تقدم شركة نوميديا وبشكل دوري مجموعة من الدورات المتنوعة في مختلف المنظومات, تتيح
                  للعاملين بمختلف القطاعات .التعلم والتدرب بشكل الصحيح على برامجنا.
                </p>
                <p>
                  يتم تنزيل جدول الدورات بصورة مستمرة في موقعنا مع امكانية التسجيل عبر الموقع,
                  للمزيد اضغط على الزر ادناه.
                </p>
              </div>
              <div className="row justify-content-center">
                <Link to="/courses" className="axil-btn btn-large btn-fill-primary">
                  اقرأ المزيد
                </Link>
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <img src={process.env.PUBLIC_URL + '/images/courses/about-course.jpg'} alt="Circle" />
          </div>
        </div>
      </div>
      {/* <ul className="shape-group-6 list-unstyled">
        <li className="shape shape-1">
          <img src={process.env.PUBLIC_URL + '/images/others/bubble-7.png'} alt="Bubble" />
        </li>
        <li className="shape shape-2">
          <img src={process.env.PUBLIC_URL + '/images/others/line-4.png'} alt="line" />
        </li>
        <li className="shape shape-3">
          <img src={process.env.PUBLIC_URL + '/images/others/line-5.png'} alt="line" />
        </li>
      </ul> */}
    </section>
  );
};

export default AboutOne;
