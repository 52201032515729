import React, { useEffect, useState } from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import QueryApiService from '../utils/reactQueryApi';
import { API_URL, HOST_URL } from '../constants/api';

const Download = () => {
  const [data, setData] = useState([]);
  const reactQueryDataApi = new QueryApiService('downloads');
  useEffect(async () => {
    const result = (await reactQueryDataApi.getData('?sort=createdAt'))?.data;
    setData(result);
  }, []);
  return (
    <>
      <SEO title="التحديثات" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <div className="breadcrum-area breadcrumb-banner">
          <div className="container">
            <div className="row justify-content-center">
              <h1 className="title h2" dangerouslySetInnerHTML={{ __html: 'التحديثات' }}></h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: 'يمكنك تحميل اخر التحديثات من هتا',
                }}
              ></p>
            </div>
            <hr />
            <div className="row justify-content-center">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">المنظومة</th>
                    <th scope="col">وصف قصير</th>
                    <th scope="col">تاريخ التحديث</th>
                    <th scope="col">توقيت التحديث</th>
                    <th scope="col">التحميل</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => {
                    return (
                      <tr>
                        <th scope="row">{i + 1}</th>
                        <td>{item?.name}</td>
                        <td>{item?.breif}</td>
                        <td>{item?.updatedAt?.slice(0, 10)}</td>
                        <td>{item?.updatedAt?.substr(11, 5)}</td>
                        <td>
                          <a href={HOST_URL + item?.file}>{item?.file?.replace('uploads', '')} </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default Download;
