import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaPinterestP,
  FaLinkedin,
  FaInstagram,
  FaVimeoV,
  FaDribbble,
  FaBehance,
  FaEnvelopeOpen,
  FaTiktok,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from "../../utils";

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-top">
          <div className="footer-social-link">
            <ul className="list-unstyled">
              <li>
                <a href="https://www.facebook.com/numedialy">
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <Link to="#">
                  <FaXTwitter />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <FaPinterestP />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <FaLinkedin />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <FaInstagram />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <FaVimeoV />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <FaDribbble />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <FaBehance />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <FaTiktok />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-10 col-lg-10">
              <div className="row">
                <div className="col-sm-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">خدماتنا</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        {getServiceData.slice(0, 5).map((data, index) => (
                          <li key={index}>
                            <Link to="#">{data.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">قد يعجك</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/blog-grid"}>
                            مقالات
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link to={process.env.PUBLIC_URL + "/case-study"}>
                            اخبار
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + "/project-grid-one"}
                          >
                            قرارات رسمية
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">الدعم الفني</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/contact"}>
                            تواصل معنا
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row justify-content-center">
            <div className="footer-copyright">
              <span className="copyright-text">
                جميع الحقوق محفوظة نوميديا© {new Date().getFullYear()}.
                <a href="https://axilthemes.com/"></a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
