import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import CounterUpTwo from "../component/counterup/CounterUpTwo";
import TeamOne from "../component/team/TeamOne";

const Team = () => {
  return (
    <>
      <SEO title="Team" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title="انت مع الاكثر خبرة"
          paragraph="إن أهمية الخبرة والمعرفة في القيادة تظهر كعامل هام في مسيرة تميّز مشروع ما أو شركة ما، ولربما يمكننا قياس نجاح مشروع ما عن طريق التعرف على مستوى خبرة ومعرفة قائد ذلك المشروع، فإن المعرفة والخبرة التي يمتلكها قائد المشروع تجعله مخولاً ليكون قائداً ناجحاً ومصدراً لإلهام قادة آخرين، وكما أن خبرة القائد تبث التفاعل والإيمان في نفس المرؤوسين بنجاح المشروع الذين يعملون عليه."
          styleClass="thumbnail-2"
          mainThumb="/images/team/team-1.png"
        />
        <CounterUpTwo />
        <TeamOne />
        {/* <VideoTwo /> */}
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default Team;
