import React from 'react';
import FormOne from '../contact/FormOne';
import { Link } from 'react-router-dom';

const AboutOne = () => {
  return (
    <section className="section section-padding-equal bg-color-light">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-us">
              <div className="section-heading heading-left mb-0">
                <span className="subtitle">ماهيا نوميديا؟</span>
                <h2 className="title mb--40">الافضل يعني نوميديا</h2>
                <p>
                  نوميديا من الشركات الرائدة في تقديم الخدمات البرمجية للحلول المالية
                  والادارية,برمجيات نوميديا متوفرة في اكثر من 90% من قطاعات الدولة في مختلف انحاء
                  ليبيا.
                </p>
                <p>
                  نوميديا اليوم تعد ايقونة ومرجع للعديد من المراقبين المالين والمحاسبين . حلول
                  نوميديا تجدها اينما تذهب.
                </p>
              </div>
              <div className="row justify-content-center">
                <Link to="/about-us" className="axil-btn btn-large btn-fill-primary">
                  اقرأ المزيد
                </Link>
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <img
              className="border shadow"
              src={process.env.PUBLIC_URL + '/images/about/numedia-building.jpg'}
              alt="Circle"
            />
          </div>
        </div>
      </div>
      <ul className="shape-group-6 list-unstyled">
        <li className="shape shape-1">
          <img src={process.env.PUBLIC_URL + '/images/others/bubble-7.png'} alt="Bubble" />
        </li>
        <li className="shape shape-2">
          <img src={process.env.PUBLIC_URL + '/images/others/line-4.png'} alt="line" />
        </li>
        <li className="shape shape-3">
          <img src={process.env.PUBLIC_URL + '/images/others/line-5.png'} alt="line" />
        </li>
      </ul>
    </section>
  );
};

export default AboutOne;
